import { NgModule } from '@angular/core';

import {
  MatCardModule,
  MatInputModule,
  MatButtonModule,
  MatToolbarModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatFormFieldModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatSortModule,
  MatTabsModule,
  MatGridListModule,
  MatSelectModule,
  MatOptionModule,
  MatDialogModule,
  MatButtonToggleModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatChipsModule,
  MatBadgeModule,
  MatAutocompleteModule,
  MatExpansionModule,
  MatCheckboxModule,
  MatRadioModule,
  MatPaginatorModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


const modules = [
  MatNativeDateModule,
  MatDatepickerModule,
  MatButtonToggleModule,
  MatDialogModule,
  MatSelectModule,
  MatOptionModule,
  MatGridListModule,
  MatCardModule,
  MatInputModule,
  MatButtonModule,
  MatToolbarModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  FormsModule,
  ReactiveFormsModule,
  MatFormFieldModule,
  MatProgressSpinnerModule,
  MatTabsModule,
  MatChipsModule,
  MatBadgeModule,
  MatAutocompleteModule,
  MatExpansionModule,
  MatCheckboxModule,
  MatRadioModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
];

@NgModule({
  imports: modules,
  exports: modules
})
export class MaterialModule { }
