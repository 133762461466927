import { Component, OnInit, Input } from '@angular/core';
import { RRFirebaseService } from 'src/app/rrcore/rrfirebase.service';
import { UserService } from 'src/app/user/user.service';
import { makeGroupFieldsInverted, DashboardComponent } from '../dashboard.component';
import { BehaviorSubject } from 'rxjs';
import { RRLib } from 'src/app/rrcore/rrlib.service';

@Component({
  selector: 'dash-monthly-view',
  templateUrl: './monthly-view.component.html',
  styleUrls: ['./monthly-view.component.scss']
})
export class MonthlyViewComponent implements OnInit {
  @Input('data') territories: any[];
  @Input() monthData
  @Input() viewTotals: any

  callReachColumns: string[] = ['territory', 'mdCount', 'mdReached', 'callReach', 'mdCalledToTarget', 'callFrequency']
  callReachOptions = {
    title: 'Call Reach and Frequency',
    columns: [
      { field: 'name_rep', title: 'Medrep' },
      { field: 'total_doctors', title: 'Doctor Count', class: 'text-center' },
      { field: 'count_doctor_reached', title: 'Doctors Reached', class: 'text-center' },
      { field: 'count_doctor_called_to_target', title: 'Doctors<br>Called To Target', class: 'text-center' },
      { field: 'call_reach', compute: (row) => { return (row.count_doctor_reached / row.total_doctors) * 100 }, title: 'Call Reach', numberFormat: '1.2-2', suffix: '%', class: 'text-center font-weight-bold' },
      { field: 'call_frequency', compute: (row) => { return (row.count_doctor_called_to_target / row.total_doctors) * 100 }, title: 'Call Frequency', numberFormat: '1.2-2', suffix: '%', class: 'text-center font-weight-bold' }
    ]
  }

  callRateOptions = {
    title: 'Call Rate',
    columns: [
      { field: 'name_rep', title: 'Medrep' },
      { field: 'total_calls', title: 'Actual Calls', class: 'text-center' },
      { field: 'total_plan', title: 'Total Planned Calls', class: 'text-center' },
      { field: 'call_reach', compute: (row) => { return (row.total_calls / row.total_plan) * 100 }, title: 'Monthly Call Rate', numberFormat: '1.2-2', suffix: '%', class: 'text-center font-weight-bold' }
    ]
  }

  dailyCalls: any[]

  repNames: string

  widgets: any[] = []

  callRatePerSpecialty : any[]
  callReachPerSpecialty : any[]
  callFreqPerSpecialty : any[]

  constructor(private ds: RRFirebaseService, private user: UserService) {

  }

  ngOnChanges() {
    if (this.territories) {
      const allDC = {}
      const repNames = []

      let total_calls_today = 0
      const today = RRLib.TODAY

      let specialty_call_rates = {}
      let specialty_call_reach = {}
      let specialty_call_freq = {}

      this.territories.forEach(terr => {
        terr.name_rep = terr.name_rep.replace(',','')
        repNames.push(terr.name_rep)
        if (terr && terr.daily_calls) {
          const dc: any[] = JSON.parse(terr.daily_calls)
          dc.forEach(a => {
            const _dc = allDC[a.d] || {}
            _dc[terr.name_rep] = a.c
            allDC[a.d] = _dc

            if (a.d == today) {
              total_calls_today += a.c || 0
            }

          })
        }

        if(terr) {
          const specialty_call_fields = Object.keys(terr).filter(a => a.startsWith('total_specialty_call'))
          const specialty_plan_fields = Object.keys(terr).filter(a => a.startsWith('total_specialty_plan'))
          const specialty_reach_fields = Object.keys(terr).filter(a => a.startsWith('total_specialty_mdreached'))
          const specialty_freq_fields = Object.keys(terr).filter(a => a.startsWith('total_specialty_mdCTT'))

          specialty_plan_fields.forEach(f => {
            let fld = f.replace('total_specialty_plan_', '')
            let spcCR = specialty_call_rates[fld] || {call : 0, plan : 0, rate : 0}

            spcCR.plan += terr[f]

            spcCR.rate = spcCR.call > 0 ? Math.ceil((spcCR.call / (spcCR.plan || 1)) * 100) : 0

            specialty_call_rates[fld] = spcCR

          })

          specialty_call_fields.forEach(f => {
            let fld = f.replace('total_specialty_call_', '')
            let spcCR = specialty_call_rates[fld] || {call : 0, plan : 0, rate : 0}

            spcCR.call += terr[f]

            spcCR.rate = spcCR.call > 0 ? Math.ceil( (spcCR.call / (spcCR.plan || 1)) * 100 ) : 0

            specialty_call_rates[fld] = spcCR

          })

          this.callRatePerSpecialty = Object.keys(specialty_call_rates).map(k => {
            return { specialty : `${specialty_call_rates[k].rate}% - ${k}`, ...specialty_call_rates[k]}
          })

          specialty_reach_fields.forEach( f => {
            let fld = f.replace('total_specialty_mdreached_', '')
            let spcCR = specialty_call_reach[fld] || {mdCount : 0, mdReached : 0, rate : 0}

            spcCR.mdReached += terr[f]
            spcCR.mdCount += (terr[`total_specialty_mdcount_${fld}`] || 1)

            spcCR.rate = spcCR.mdReached > 0 ? Math.ceil( (spcCR.mdReached / (spcCR.mdCount || 1)) * 100 ) : 0

            specialty_call_reach[fld] = spcCR
          })

          this.callReachPerSpecialty = Object.keys(specialty_call_reach).map(k => {
            return { specialty : `${specialty_call_reach[k].rate}% - ${k}`, ...specialty_call_reach[k]}
          })

          specialty_freq_fields.forEach( f => {
            let fld = f.replace('total_specialty_mdCTT_', '')
            let spcCR = specialty_call_freq[fld] || {mdCount : 0, mdCTT : 0, rate : 0}

            spcCR.mdCTT += terr[f]
            spcCR.mdCount += (terr[`total_specialty_mdcount_${fld}`] || 0)

            spcCR.rate = ((spcCR.mdCTT*spcCR.mdCount) > 0) ? Math.ceil( (spcCR.mdCTT / spcCR.mdCount) * 100 ) : 0

            specialty_call_freq[fld] = spcCR
          })

          this.callFreqPerSpecialty = Object.keys(specialty_call_freq).map(k => {
            return { specialty : `${specialty_call_freq[k].rate}% - ${k}`, ...specialty_call_freq[k]}
          })

        }

      })


      this.dailyCalls = Object.keys(allDC).map(day => {
        return { date: day, ...allDC[day] }
      }).sort((a,b) => {return a.date > b.date ? 1 : a.date < b.date ? -1 : 0 })
      console.log('DAILY CALLS', this.dailyCalls)

      if (this.viewTotals) {
        this.widgets = [
          {
            name: 'Calls Today vs Target Daily Calls',
            actual: total_calls_today,
            target: this.viewTotals.total_plan / this.monthData.count_working_days,
            class : "col-3"
          },
          {
            name: 'Ave. Daily Calls vs Target Daily Calls',
            actual: (this.viewTotals.total_calls - total_calls_today) / (this.dailyCalls.filter(a => {return a.date < today}).length),
            target: this.viewTotals.total_plan / this.monthData.count_working_days,
            class : "col-3"
          }
          ,
          {
            name: 'Total Calls vs Total Plan',
            actual: this.viewTotals.total_calls,
            target: this.viewTotals.total_plan,
            class : "col-3"
          },
          {
            name: 'Total MDs Reached vs Total MDs',
            actual: this.viewTotals.count_doctor_reached,
            target: this.viewTotals.total_doctors,
            class : "col-3"
          }
        ]
      }
      this.repNames = repNames.join(',')
      
    }
  }

  ngOnInit() {
  }



}
