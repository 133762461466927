import { Component, OnInit, Input } from '@angular/core';
import { makeGroupFieldsInverted, makeGroupFields } from '../dashboard/dashboard.component';

@Component({
  selector: 'dash-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.scss']
})
export class ProductViewComponent implements OnInit {
  @Input('data') territories: any[];
  @Input() monthData
  @Input() viewTotals: any


  productTotals = []
  productLOD = []

  constructor() { }

  ngOnInit() {

  }

  ngOnChanges() {
    const terrs = this.territories
    const prodTotals = {}
    const prodLOD = {}
    console.log('Updating product view...')
    if (terrs) {
      terrs.forEach(terr => {
        Object.keys(terr).filter(a => { return a.startsWith('total_product_plan_') })
          .forEach(p => {
            const prodName = p.replace('total_product_plan_', '')
            const prod = prodTotals[prodName] || { plan: 0, calls: 0 }

            prod.plan += terr[p] || 0
            prod.calls += terr[`total_product_call_${prodName}`] || 0

            prodTotals[prodName] = prod

            const lod = prodLOD[prodName] || { count: 0, lod: 0 }
            lod.count += terr[`count_LOD_${prodName}`] || 0
            lod.lod += terr[`total_LOD_${prodName}`] || 0
            prodLOD[prodName] = lod

          })
      })

      this.productTotals = []
      for (const p in prodTotals) {
        const prod = prodTotals[p]
        if (prod.plan && prod.calls) {
          this.productTotals.push(
            {
              name: p,
              actual: prod.calls,
              target: prod.plan,
              class: "col-3 p-1"
            })
        }
      }

      this.productLOD = []
      for (const p in prodLOD) {
        const lod = prodLOD[p]
        if (lod.lod > 0) {
          this.productLOD.push({ name: p, average: Math.ceil(lod.lod / lod.count), count: lod.count })
        }
      }
    }
  }
}
