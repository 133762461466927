import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RRCoreModule } from './rrcore/rrcore.module';

import { LayoutModule } from '@angular/cdk/layout';
import { DashboardModule } from './dashboard/dashboard.module';
import { UserModule } from './user/user.module';
import { MaterialModule } from './material/material.module';
import { RRFirebaseService } from './rrcore/rrfirebase.service';
import { UserService } from './user/user.service';
import { IgniteDataService } from './rrcore/ignite-data.service';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RRCoreModule,
    LayoutModule,
    DashboardModule,
    MaterialModule,
    UserModule
  ],
  providers: [RRFirebaseService, UserService, IgniteDataService],
  bootstrap: [AppComponent]
})
export class AppModule { }
