import { Injectable } from '@angular/core';
import { RRLib } from '../rrcore/rrlib.service';
import { RRFirebaseService } from '../rrcore/rrfirebase.service';
import { UserData, UserService } from '../user/user.service';
import { IgniteDataService } from '../rrcore/ignite-data.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerDataService {

  private source: { 'doctor_cliniic', 'doctor_clinic_master' }

  custObservers = {}

  customers = {}
  customerIDs = new BehaviorSubject<string[]>([])

  isAdmin = new BehaviorSubject<boolean>(false)

  specialties = new BehaviorSubject<string[]>([])

  territory_ids = new BehaviorSubject<string[]>(null)
  territoryInfo = new BehaviorSubject<any>({})


  constructor(private ds: RRFirebaseService, private user: UserService) {

    this.user.territory_ids.subscribe(u => {
      if (u) {
        // this.userData = u
        this.territory_ids.next(u)
      }
      this.isAdmin.next(this.user.isAllowed('admin'))
    })

    const territoryInfo = {}
    const specialties = {}

    this.territory_ids.subscribe(terrs => {

      if (terrs && this.ds) {
        const aTerrInfo = this.territoryInfo.getValue()

        let timer = null

        terrs.forEach(terr => {

          if (aTerrInfo[terr] == null) {
            this.ds.fbObserveChildAdded(`${this.user.client}/doctor_clinic/${terr}`, (snap, s) => {

              const cust = new CustomerData(snap)
              if (cust.last_name > '') {
                cust.territory = terr
                this.customers[cust.md_id] = cust
                if (cust.specialty_description) {
                  specialties[cust.specialty_description.toUpperCase()] = 1
                }
                if (timer) {
                  clearTimeout(timer)
                }

                timer = setTimeout(() => {
                  this.customerIDs.next(Object.keys(this.customers))
                  this.specialties.next(Object.keys(specialties).sort((a, b) => { return a > b ? 1 : a < b ? -1 : 0 }))

                },  1000)
              }

            })

            this.ds.getValue(`${this.user.client}/territories/${terr}/${RRLib.CYCLE}`).then(tInfo => {
              if (tInfo) {
                tInfo.territory_id = terr
                territoryInfo[terr] = tInfo
                this.territoryInfo.next(territoryInfo)
              }
            })
          }

        })
      }
    })

  }

  approveDeletion(c: CustomerData): Promise<void> {
    if (c.delete_notification) {
      let notif_ref = c.delete_notification

      const currUsername = this.user.userData.getValue().name
      const now = RRLib.dateToLongString(new Date())

      const newNotif = {
        subject: `DELETION APPROVED ${c.last_name}, ${c.first_name} (${c['delete_reason']})`,
        message: `DELETION of ${c.last_name}, ${c.first_name}  (${c['delete_reason']}) - was APPROVED by ${currUsername}`,
        type: 'Receipt',
        actions: 'Ok',
        datetime: now,
        from: this.user.userName,
        icon: 'check'
      }

      return this.ds.getValue(`${this.user.client}/doctor_clinic/${c.territory}/${c.md_id}`).then(data => {

        return this.ds.fbSetValue(`${this.user.client}/deleted_doctor/${c.territory}/${c.md_id}`, data).then(() => {

          delete this.customers[c.md_id]
          this.customerIDs.next(Object.keys(this.customers))

          return this.ds.fbRemoveValue(`${this.user.client}/doctor_clinic/${c.territory}/${c.md_id}`).then(() => {
            this.ds.fbUpdateValue(`${this.user.client}/${notif_ref}`, { seen: now, approve_date: now }).then(() => {
              if (c.username) {
                return this.ds.fbSetValue(`${this.user.client}/notification/${c.username}/${this.user.userName}-${now}`, newNotif)
                  .then(() => {
                    alert('Deletion Approved.')
                  })
              } else {
                return null
              }
            })
          })
        })

      })
    } else {
      alert('This customer is not marked for Deletion!')
      return null
    }
  }

  approveInclusion(customer: CustomerData): Promise<void> {
    const userId = customer.username
    const currUsername = this.user.userData.getValue().name
    const currUserID = this.user.userName

    if (customer.inclusion_notification) {

      let notif_ref = customer.inclusion_notification
      const now = RRLib.dateToLongString(new Date())

      const newNotif = {
        subject: `INCLUSION APPROVED ${customer.last_name}, ${customer.first_name}`,
        message: `INCLUSION of ${customer.last_name}, ${customer.first_name}  (${customer.specialty_description}) - was APPROVED by ${currUsername}`,
        type: 'Receipt',
        actions: 'Ok',
        datetime: now,
        from: currUserID,
        icon: 'check'
      }

      console.dir(newNotif)
      console.log(`${this.user.client}/notification/${userId}/${currUserID}-${now}`)
      delete customer.inclusion_notification
      this.customerUpdateColor(customer)

      return this.ds.fbRemoveValue(`${this.user.client}/doctor_clinic/${customer.territory}/${customer.md_id}/inclusion_notification`)
        .then(() => {
          if (userId) {
            return this.ds.fbSetValue(`${this.user.client}/notification/${userId}/${currUserID}-${now}`, newNotif)
              .then(() => {
                alert('Inclusion Approved.')
              })
          } else {
            return null
          }
        })

    } else {
      alert('This customer is not marked for Inclusion!')
      return null
    }
  }

  customerUpdateColor(c: CustomerData) {
    c.color = (c.delete_notification != null) ? 'white' : (c.inclusion_notification != null) ? 'white' : 'black';
    c.bg_color = (c.delete_notification != null) ? 'orange' : (c.inclusion_notification != null) ? 'gray'
      : c.call_status == 'Called To Target' ? 'rgb(140, 241, 114)' : c.call_status == 'Reached' ? 'rgb(243, 235, 127)' : 'rgb(243, 235, 235)';

  }

  declineDeletion(c: CustomerData): Promise<void> {
    const userId = c.username
    if (c.delete_notification) {
      const currUsername = this.user.userData.getValue().name
      let notif_ref = c.delete_notification
      const now = RRLib.dateToLongString(new Date())

      const newNotif = {
        subject: `DELETION DECLINED ${c.last_name}, ${c.first_name} (${c['delete_reason']})`,
        message: `DELETION of ${c.last_name}, ${c.first_name} (${c['delete_reason']}) - was DECLINED by ${currUsername}`,
        type: 'Receipt',
        actions: 'Ok',
        datetime: now,
        from: this.user.userName,
        icon: 'alert'
      }



      console.dir(newNotif)
      console.log(`${this.user.client}/notification/${userId}/${this.user.userName}-${now}`)

      return this.ds.fbRemoveValue(`${this.user.client}/doctor_clinic/${c.territory}/${c.md_id}/delete_notification`).then(() => {
        delete c.delete_notification
        delete c['delete_reason']
        this.customerUpdateColor(c)

        return this.ds.fbRemoveValue(`${this.user.client}/doctor_clinic/${c.territory}/${c.md_id}/delete_reason`).then(() => {

          if (userId) {
            return this.ds.fbSetValue(`${this.user.client}/notification/${userId}/${this.user.userName}-${now}`, newNotif)
              .then(() => {
                alert('Deletion Declined.')
              })
          } else {
            return null
          }
        })
      })

    } else {
      alert('This customer is not marked for Deletion!')
      return null
    }
  }

  declineInclusion(c: CustomerData): Promise<void> {
    if (c.inclusion_notification) {
      let notif_ref = c.inclusion_notification
      const currUsername = this.user.userData.getValue().name

      return this.ds.getValue(`${this.user.client}/doctor_clinic/${c.territory}/${c.md_id}`).then(data => {
        const now = RRLib.dateToLongString(new Date())
        data.inclusion_deny_date = now
        data.inclusion_deny_by = this.user.userName

        return this.ds.fbSetValue(`${this.user.client}/deleted_doctor/${c.territory}/${c.md_id}`, data).then(() => {

          return this.ds.fbRemoveValue(`${this.user.client}/doctor_clinic/${c.territory}/${c.md_id}`).then(() => {

            delete this.customers[c.md_id]
            this.customerIDs.next(Object.keys(this.customers))

            return this.ds.fbUpdateValue(`${this.user.client}/${notif_ref}`, { seen: now, deny_date: now }).then(() => {
              const newNotif = {
                subject: `INCLUSION DECLINED ${c.last_name}, ${c.first_name}`,
                message: `INCLUSION of ${c.last_name}, ${c.first_name}  - was DECLINED by ${currUsername}`,
                type: 'Receipt',
                actions: 'Ok',
                datetime: now,
                from: this.user.userName,
                icon: 'alert'
              }



              if (c.username) {
                return this.ds.fbSetValue(`${this.user.client}/notification/${c.username}/${this.user.userName}-${now}`, newNotif)
                  .then(() => {
                    alert('Inclusion Declined.')
                  })
              } else {
                return null
              }

            })
          })
        })

      })
    } else {
      alert('This customer is not marked for Inclusion!')
      return null
    }
  }

  static getCustomerKey(cData: CustomerData): string {

    const p1 = (cData.last_name || '-').substring(0, 1);
    const p2 = (cData.first_name || '-').substring(0, 1) || '-';
    const p3 = (cData.middle_name || '-').substring(0, 1) || '-';

    const str = `${(cData.last_name || '-')}${(cData.first_name || '-')}${(cData.middle_name || '-')}${(cData.specialty_description || '-')}`
    const p4 = RRLib.codeFromString(str.toUpperCase());

    return (p1 + p2 + p3 + p4).toUpperCase();
  }

  getCustomerKey(cData: CustomerData): string {
    return CustomerDataService.getCustomerKey(cData)
  }

  getCustomerCalls(c: CustomerData): Promise<any[]> {
    return this.ds.getValue(`${this.user.client}/calls_per_customer/${RRLib.CYCLE}/${c.territory}/${c.md_id}`)
      .then(dates => {
        if (dates) {
          const pa = []
          const calls = []
          Object.keys(dates).forEach(date => {
            pa.push(this.ds.getValue(`${this.user.client}/calls/${c.territory}/${date}/${c.md_id}`)
              .then(call => {
                calls.push(call)
              })
            )
          })
          return Promise.all(pa).then(v => {
            return calls
          })
        }
        return null
      })
  }

  getCustomerNotes(mdid: string, start: string, end: string): Promise<any[]> {
    console.log(`${this.user.client}/notes_per_customer/${mdid}`, start, end)
    return this.ds.getValue(`${this.user.client}/notes_per_customer/${mdid}`)
      .then(d => {
        if (d) {
          console.log('NTEST', d)
          const rval = []
          Object.keys(d).forEach(a => {
            d[a].id = a
            rval.push(d[a])
          })
          return rval
        }
        return null
      })

  }

  getCustomerNoteswithDate(mdid: string, start: string, end: string): Promise<any[]> {
    // console.log(`${this.user.client}/notes_per_customer/${mdid}`, start, end)
    return this.ds.getValueWhere(`${this.user.client}/notes_per_customer/${mdid}`, start, end)
      .then(d => {
        if (d) {
          // console.log('NTEST', d)
          const rval = []
          Object.keys(d).forEach(a => {
            d[a].id = a
            rval.push(d[a])
          })
          return rval
        }
        return null
      })

  }

  saveCustomerData(c: CustomerData) {
    var rec = {};

    for (const fname in c) {
      if ('toggleDelete,_callCount,callCount,isFromMasterList,_call_status,call_status,color,bg_color,cycle,snap,key,rrd,$key,territory,username,source,save,getCustomerKey'.indexOf(fname) == -1) {
        let f = fname.toString();
        let v = this[f]
        if (v != null && v != "") {
          if (typeof v != 'object') {
            rec[f] = v;
          }
        }
      }
    }

    rec['territory_id'] = c.territory;
    rec['userid'] = this.user.userName;

    if (c.md_id == null || c.md_id == 'New') {

      rec['inclusion_notification'] = `saved from IM2 ${c['source']}`

      const mdID = this.getCustomerKey(<CustomerData>rec);

      rec['md_id'] = mdID;

      const ref = `${this.user.client}/${this['source']}/${c.territory}/${mdID}`;


      this.ds.fbUpdateValue(ref, rec);

    } else {

      const ref = `${this.user.client}/${this['source']}/${c.territory}/${c.md_id}`;

      this.ds.fbUpdateValue(ref, rec);
      // this.snap.ref.set(rec);

    }
  }

  saveNote(c: CustomerData, note: any): Promise<void> {
    const ref = `${this.user.client}/notes/${c.territory}/${RRLib.CYCLE}/${note.datetime}`;
    return this.ds.fbUpdateValue(ref, note);
  }

  toggleCustomerDelete(c: CustomerData) {
    if (c.delete_notification != null) {
      delete c.delete_notification
      delete c['delete']

      this.ds.fbRemoveValue(`${this.user.client}/doctor_clinic/${c.territory}/${c.md_id}/delete_notification`)
      this.ds.fbRemoveValue(`${this.user.client}/doctor_clinic/${c.territory}/${c.md_id}/delete`)
    } else {
      c.delete_notification = `delete requested via IM2 ${this.user.userName} on ${Date()}`
      c['delete'] = 'yes'
      this.ds.fbUpdateValue(`${this.user.client}/doctor_clinic/${c.territory}/${c.md_id}`,
        {
          delete_notification: `delete requested via IM2 ${this.user.userName} on ${Date()}`,
          delete: 'yes'
        }
      )
    }
  }

  updateTerritories(tids: string[]) {
    this.territory_ids.next(tids)
  }

}


export class CustomerData {

  key: string;
  first_name: string;
  specialty_description: string;
  last_name: string;
  middle_name: string;
  class_code: string;
  frequency: string = '';
  clinic_address: string;
  md_id: string;
  $key: string;
  childx: any;
  lic_no: string;
  address_city: string;
  gender: string;
  birth_date: string;
  profile_pic: string;
  delete_notification: string;
  inclusion_notification: string;
  territory: string;
  username: string;
  bg_color: string;

  color: string;

  call_status: string;
  _call_status(): string {

    const frequency = (typeof this.frequency == 'string') ? parseInt(this.frequency.replace(/x/gi, '')) : this.frequency;
    const callCount = this.callCount

    return callCount >= frequency ? 'Called To Target' : callCount > 0 ? 'Reached' : 'Unreached';

  }

  callCount: number;
  _callCount(): number {

    const cycle = RRLib.CYCLE

    const callsField = `calls_${cycle}`;

    const callCount = parseInt(this[callsField] || '0');

    return callCount

  }


  isFromMasterList: boolean = false;
  // get isFromMasterList(): boolean {
  //   return this.snap.ref.path.toString().indexOf("doctor_clinic_master") != -1;
  // }


  constructor(snap: firebase.database.DataSnapshot) {

    // ImFireService.currentCycle.subscribe(cycle => this.cycle = cycle)

    // this.cycle = RRLib.CYCLE

    if (snap == null) {
      this.key = 'New';
      this.md_id = '(draft)'
    } else {

      this.key = snap.key;
      const v = snap.val();

      Object.keys(v).forEach(k => {
        this[k] = v[k];
      })

      // this.isFromMasterList = this.snap.ref.toString().indexOf("doctor_clinic_master") != -1;
      // this.isFromMasterList = this.snap.ref.toString() == "doctor_clinic_master";
      // console.log(this.isFromMasterList)


    }



    this.territory = this['territory_id']



    this.callCount = this._callCount();
    this.call_status = this._call_status();

    this.color = (this.delete_notification != null) ? 'white' : (this.inclusion_notification != null) ? 'white' : 'black';
    this.bg_color = (this.delete_notification != null) ? 'orange' : (this.inclusion_notification != null) ? 'gray'
      : this.call_status == 'Called To Target' ? 'rgb(140, 241, 114)' : this.call_status == 'Reached' ? 'rgb(243, 235, 127)' : 'rgb(243, 235, 235)';

  }

}

export interface Note {
  author: string,
  author_name: string,
  cust_name: string,
  datetime: string,
  md_id: string,
  note: string
}
