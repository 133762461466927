import { Component, Input } from '@angular/core'
import { RRFirebaseService } from 'src/app/rrcore/rrfirebase.service'
import { UserService } from 'src/app/user/user.service'
import { makeGroupFieldsInverted, makeGroupFields, DashboardComponent } from './dashboard.component'
import { BehaviorSubject } from 'rxjs'

@Component({
    selector: 'dash-target-view',
    templateUrl: './target-view.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class TargetViewComponent {
    @Input('data') territories: any[]
    @Input('monthData') monthData 

    doctorsPerClass = []

    doctorsPerFrequencyCols = []

    doctorsCountPerProduct = {
        title: 'Doctor Count Per Product',
        columns: [
            { field: 'name_rep', title: 'Medrep', class: 'text-left' }
        ]
    }

    doctorsCountPerSpecialty = {
        title: 'Doctor Count Per Specialty',
        columns: [
            { field: 'name_rep', title: 'Medrep', class: 'text-left' }
        ]
    }

    planCountPerSpecialty = {
        title: 'Target Calls Per Specialty',
        columns: [
            { field: 'name_rep', title: 'Medrep', class: 'text-left' }
        ]
    }

    planCountPerProduct = {
        title: 'Target Calls Per Product',
        columns: [
            { field: 'name_rep', title: 'Medrep', class: 'text-left' }
        ]
    }

    planCallsPerFrequencyCols = []

    specialties: string[] = []

    widgets: any[] = []


    constructor(private ds: RRFirebaseService, private user: UserService) {

    }

    ngOnChanges() {
        const terrs = this.territories
        if (terrs) {
            const mdCountBySpec = makeGroupFieldsInverted(terrs, 'total_specialty_mdcount_');
            this.doctorsCountPerSpecialty.columns = mdCountBySpec.cols
            this.doctorsCountPerSpecialty['data'] = mdCountBySpec.data

            const planCountBySpec = makeGroupFieldsInverted(terrs, 'total_specialty_plan_');
            this.planCountPerSpecialty.columns = planCountBySpec.cols
            this.planCountPerSpecialty['data'] = planCountBySpec.data

            const mdCountByProd = makeGroupFieldsInverted(terrs, 'total_product_mdcount_')
            this.doctorsCountPerProduct.columns = mdCountByProd.cols
            this.doctorsCountPerProduct['data'] = mdCountByProd.data

            const planCountByProd = makeGroupFieldsInverted(terrs, 'total_product_plan_')
            this.planCountPerProduct.columns = planCountByProd.cols
            this.planCountPerProduct['data'] = planCountByProd.data

            this.doctorsPerFrequencyCols = makeGroupFields(terrs, 'total_frequency_mdcount_').slice(1).map(a => { return { title: `${a.title}X MDs`, field: a.field } })
            this.planCallsPerFrequencyCols = makeGroupFields(terrs, 'total_frequency_plan_').slice(1).map(a => { return { title: `${a.title}X MD Calls`, field: a.field } })

            // this.doctorsPerClass = makeGroupFields(terrs, 'total_class_mdcount_').slice(1).map( a => {return {title : `Class ${a.title} MDs`, field : a.field }})
            let total_doctors = 0
            let total_plan = 0
            terrs.forEach(terr => {
                total_doctors += terr.total_doctors || 0
                total_plan += terr.total_plan || 0
            })

            if (this.monthData) {
                const md = this.monthData
                if (this.widgets.length == 0 && md) {
                    this.widgets = [{
                        name: 'Total Doctor Count',
                        actual: total_doctors,
                        class : 'col-3'
                    }, {
                        name: 'Total Target Calls',
                        actual: total_plan,
                        class : 'col-3'
                    }, {
                        name: 'Working Days',
                        actual: md.count_working_days,
                        class : 'col-3'
                    }, {
                        name : 'Average Calls Per Day',
                        actual: total_plan / md.count_working_days / terrs.length,
                        class : 'col-3'
                    }]
                }
            }
        }
    }
}