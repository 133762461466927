import { Component, OnInit, OnChanges, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'chips-autocomplete',
  templateUrl: './chips-autocomplete.component.html',
  styleUrls: ['./chips-autocomplete.component.scss']
})
export class ChipsAutocompleteComponent implements OnInit, OnChanges {

  @Input('data') data: string;
  @Input('field') field: any;
  @Input('options') options: string[];
  @Output('chipChange') chipChange: EventEmitter<string>;

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  chipCtrl = new FormControl();
  filteredOptions: Observable<string[]>;
  values: string[] = [];
  allOptions: string[] = [];

  @ViewChild('chipInput', {static: false}) chipInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;

  constructor() { 
    this.chipChange = new EventEmitter<string>();
    this.filteredOptions = this.chipCtrl.valueChanges.pipe(
      startWith(null),
      map((option: string | null) => option ? this._filter(option) : this.allOptions.slice()));
  }

  ngOnInit() {
    this.data.split('||').forEach(value => {
      if ((value || '').trim()) {
        this.values.push(value.trim());
      }
    })
    
  }

  ngOnChanges(){
    const field = this.field
    // console.log(field,field['options'])
    for(var index in field['options']) {
      this.allOptions[index] = field.options[index]
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.values.push(value.trim());
      this.data = this.values.join('||')
      this.chipChange.emit(this.data)
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.chipCtrl.setValue(null);
  }

  remove(chip: string): void {
    const index = this.values.indexOf(chip);

    if (index >= 0) {
      this.values.splice(index, 1);
      this.data = this.values.join('||')
      this.chipChange.emit(this.data)
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.values.push(event.option.viewValue);
    this.data = this.values.join('||')
    console.log(`Data Changed: ${this.data}`)
    this.chipChange.emit(this.data)
    this.chipInput.nativeElement.value = '';
    this.chipCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allOptions.filter(options => options.toLowerCase().indexOf(filterValue) === 0);
  }
}
