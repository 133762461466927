import { Injectable } from "@angular/core";
import { UserService, UserData } from "../user/user.service";
import { RRFirebaseService } from "../rrcore/rrfirebase.service";
import { BehaviorSubject, Subscription } from "rxjs";
import { RRLib } from "../rrcore/rrlib.service";
import { resolve } from 'url';
import { CustomerData, CustomerDataService } from '../customers/customer-data.service';

@Injectable({
  providedIn: "root"
})
export class AdminDataService {
  private holidays: any[];

  userIDList = new BehaviorSubject<string[]>([]);
  userData = {};

  territoryIDs = new BehaviorSubject<string[]>(null);
  territoryData = {};
  masterLists = {}
  doctorClinics = {}
  terrsWithCustomers = {}
  terrsWithCalls = {}
  cache = {}

  firstLineManagers = new BehaviorSubject<any>(null);

  savedNextCycleConfig = new BehaviorSubject<boolean>(false)
  selectedTerritories = []

  constructor(private user: UserService, private ds: RRFirebaseService) {

    this.ds.fbObserveChildAdded(`${this.user.client}/users`, data => {
      const lUser = data.val() as UserData;
      lUser.userid = data.key;
      if (lUser.profile_picture && (lUser.profile_picture.indexOf('|') === -1)) {
        this.ds
          .getDownloadURL(`${this.user.client}/${lUser.profile_picture}`)
          .then(path => {
            lUser["profile_url"] = path;
          });
      }

      this.userData[data.key] = lUser;
      this.userIDList.next(Object.keys(this.userData));
    });

    this.ds.shallowRead(`default/territory_config/${RRLib.nextCycle()}`).then((tc) => {

      if (tc) {
        this.savedNextCycleConfig.next(Object.keys(tc).indexOf(this.user.client) !== -1)
      } else {
        this.savedNextCycleConfig.next(false)
      }
    
    })

    this.ds.shallowRead(`${this.user.client}/territories`).then(terrs => {
      const pa = [];
      const flms = {};

      if (this.user.lookups && !this.user.lookups["territory_ids"]) {
        const tids = Object.keys(terrs)
          .sort(RRLib.sort)
          .join("||");
        this.user.lookups["territory_ids"] = tids;
        console.log("setting lookups territory_ids", tids);
      }

      Object.keys(terrs).forEach(tid => {
        pa.push(
          this.ds
            .getValue(`${this.user.client}/territories/${tid}/${RRLib.CYCLE}`)
            .then(td => {
              if (td) {
                const flm = flms[td.manager.id] || td.manager || {};
                const flmTerrs = flm.terrs || [];

                flmTerrs.push(tid);
                flm.terrs = flmTerrs;

                flms[td.manager.id] = flm;

                this.territoryData[tid] = td;
              }
            })
        );
      });

      Promise.all(pa).then(() => {
        this.firstLineManagers.next(flms);
        this.territoryIDs.next(Object.keys(terrs));

        Object.keys(flms).forEach(mid => {
          this.ds.getValue(`${this.user.client}/users/${mid}`).then(mdat => {
            if (mdat) {
              flms[mid].userInfo = <UserData>mdat;
              if (mdat.profile_picture) {
                return this.ds
                  .getDownloadURL(`${this.user.client}/${mdat.profile_picture}`)
                  .then(url => {
                    flms[mid].profile_url = url;
                  });
              }
            }
          });
        });
      });
    });

    // this.ds.getValue(`/default/processing_schedule/${this.user.client}`)

  }

  applyTerritoryConfigurationToCurrentCycle(flms: FLM[], uts: string[]): Promise<any> {
    // Remove unassigned territories
    uts.forEach(terr => {
      this.ds.fbRemoveValue(`${this.user.client}/territories/${terr}/${RRLib.CYCLE}`)
    })

    const pa = []
    const errs = []
    flms.forEach(flm => {
      const territory_id = flm.terrs.join('||')

      if (!flm.name) {
        flm.name = `UNKNOWN FLM USER : ${flm.id}`
      }

      if (this.userData[flm.id]) {
        // Update FLM user territory_id 
        pa.push(this.ds.fbSetValue(`${this.user.client}/users/${flm.id}/territory_id`, territory_id))
      } else {
        pa.push(this.ds.fbSetValue(`${this.user.client}/users/${flm.id}/`, { name: flm.name, territory_id: territory_id, user_type: 'manager' }))
      }

      flm.terrs.forEach(terr => {

        const td = this.territoryData[terr]
        td.manager = { id: flm.id, name: flm.name }

        if (this.userData[td.userid] && this.userData[td.userid].name) { // Check if this is a valid User
          // Update territories node for this territory
          pa.push(this.ds.fbSetValue(`${this.user.client}/territories/${terr}/${RRLib.CYCLE}`, td))
          // Update rep territory_id
          pa.push(this.ds.fbSetValue(`${this.user.client}/users/${td.userid}/territory_id`, terr))
        } else {
          errs.push(`${flm.name} has an invalid user (${td.userid}) assigned to a territory (${terr}).`)
        }

      })

    })

    return Promise.all(pa).then(() => errs)

  }

  applyTerritoryConfigurationToNextCycle(flms: FLM[], uts: string[]): Promise<any> {

    return new Promise((res, rej) => {
      const territoryConfig = {
        removeTerritoryIDs: uts.join('~~'),
        newTerritoryAssignments: Object.keys(this.userData).reduce((p, c) => { p[c] = 'UNK'; return p }, {}),
        newUsers: {},
        territories: {}
      }

      const pa = []
      const errs = []
      flms.forEach(flm => {
        if (!flm.id || !(flm.id > '')) {
          flm.id = 'UNK'
        }
        const territory_id = flm.terrs.join('||')

        if (!flm.name) {
          flm.name = `UNKNOWN FLM USER : ${flm.id}`
        }

        if (this.userData[flm.id] && territory_id) {
          // Update FLM user territory_id 
          territoryConfig.newTerritoryAssignments[flm.id] = territory_id

        } else {
          // New User
          territoryConfig.newUsers[flm.id] = { name: flm.name, territory_id: territory_id, user_type: 'manager' }
        }

        flm.terrs.forEach(terr => {

          const td = this.territoryData[terr]
          td.manager = { id: flm.id, name: flm.name }

          if (this.userData[td.userid] && this.userData[td.userid].name) { // Check if this is a valid User
            // Update territories node for this territory
            territoryConfig.territories[terr] = td

            // Update rep territory_id
            territoryConfig.newTerritoryAssignments[td.userid] = terr
            this.savedNextCycleConfig.next(true)

          } else {

            errs.push(`${flm.name} has an invalid user (${td.userid}) assigned to a territory (${terr}).`)

          }

        })

      })

      if (errs.length > 0) {
        rej(errs)
      } else {
        res(territoryConfig)
      }

    })
      .then((config) => {
        console.log('SAVING CONFIG', config)
        return this.ds.fbSetValue(`/default/territory_config/${RRLib.nextCycle()}/${this.user.client}/`, config)
          .then(() => {
            this.ds.fbSetValue(`default/processing_schedule/${this.user.client}/applyTerritoryConfig`, true)
          })
      })

  }

  approveTerritory(tid: string) {

    const approveTerr = (dc: any): Promise<any> => {
      const ra = []
      Object.keys(dc).forEach(key => {

        const cData = dc[key] as CustomerData

        if (cData && (!cData.md_id || !cData.last_name || cData.delete_notification)) {
          ra.push(this.ds.fbRemoveValue(`${this.user.client}/doctor_clinic/${tid}/${key}`))
        }
        if (cData.inclusion_notification != null) {

          const oldMDID = key;
          const newMDID = CustomerDataService.getCustomerKey(cData);

          cData['md_id'] = newMDID;
          delete cData['inclusion_notification'];

          if (oldMDID !== newMDID) {
            ra.push(this.ds.fbRemoveValue(`${this.user.client}/doctor_clinic/${tid}/${oldMDID}`))
            ra.push(this.ds.fbUpdateValue(`${this.user.client}/doctor_clinic/${tid}/${newMDID}`, cData))
            ra.push(this.ds.fbUpdateValue(`${this.user.client}/doctor/${tid}/${newMDID}`, cData))
          }
        }

      })

      return Promise.all(ra)
    }

    if (this.doctorClinics[tid]) {
      return approveTerr(this.doctorClinics[tid])
    } else {
      return this.downloadCustomerBackup(tid)
        .then(dc => {
          return approveTerr(dc)
        })
    }
  }

  copyTerritoryMasterToCurrent(tid: string): Promise<any> {

    const copyTerritory = (ml: CustomerData[], tid: string): Promise<any> => {

      const newDC = ml.reduce((p, c) => { p[c.md_id] = c; return p }, {})
      const newMDIDs = Object.keys(newDC)
      let deleteMDIDs: string[]
      const pa = []
      this.doctorClinics[tid] = newDC

      if (this.terrsWithCustomers[tid]) {
        pa.push(this.readTerritoryCurrentMDIDs(tid).then((mdids) => { deleteMDIDs = mdids.filter(a => newMDIDs.indexOf(a) == -1) })
          .then(() => {
            const pa1 = []
            deleteMDIDs.forEach((mdid) => {
              pa1.push(
                this.ds.fbRemoveValue(`${this.user.client}/doctor_clinic/${tid}/${mdid}`)
              )
            })
            return Promise.all(pa1)
          })
        )
      }

      ml.forEach(c => {
        pa.push(this.ds.fbUpdateValue(`${this.user.client}/doctor_clinic/${tid}/${c.md_id}`, c))
      })

      return Promise.all(pa).then(() => newMDIDs)

    }

    const data = this.masterLists[tid]
    if (!data) {
      return this.readTerritoryMasterList(tid)
        .then(ml => {
          this.masterLists[tid] = ml
          return copyTerritory(ml, tid)
        })
    } else {
      return copyTerritory(data, tid)
    }

  }


  deleteHoliday(h: Holiday): Promise<void> {
    const nDate = RRLib.dateToLongString(new Date(h.date), 'yyyy-MM-dd');
    if (nDate) {
      return this.ds.fbRemoveValue(`${this.user.client}/holidays/${nDate}`);
    }
    return Promise.resolve();
  }

  denyTerritory(tid: string) {

    const denyTerr = (dc: any): Promise<any> => {
      const ra = []
      Object.keys(dc).forEach(key => {

        const cData = dc[key] as CustomerData

        if (cData && (!cData.md_id || !cData.last_name || cData.inclusion_notification)) {
          ra.push(this.ds.fbRemoveValue(`${this.user.client}/doctor_clinic/${tid}/${key}`))
        }
        if (cData.delete_notification != null) {

          ra.push(this.ds.fbRemoveValue(`${this.user.client}/doctor_clinic/${tid}/${key}/delete_notification`))

        }

      })

      return Promise.all(ra)
    }

    if (this.doctorClinics[tid]) {
      return denyTerr(this.doctorClinics[tid])
    } else {
      return this.downloadCustomerBackup(tid)
        .then(dc => {
          return denyTerr(dc)
        })
    }

  }

  downloadCustomerBackup(tid: string): Promise<CustomerData[]> {
    if (this.doctorClinics[tid]) {
      RRLib.downloadObjectAsFile(this.doctorClinics[tid], `doctor_clinic_${tid}.json`)
      return Promise.resolve(this.doctorClinics[tid])
    } else {

      return this.ds.getValue(`${this.user.client}/doctor_clinic/${tid}`)
        .then(dc => {
          this.doctorClinics[tid] = dc
          RRLib.downloadObjectAsFile(dc, `doctor_clinic_${tid}.json`)
          return this.doctorClinics[tid]
        })
    }
  }


  downloadTerritoryConfig(filename: string) {
    const saveObject = {
      client: this.user.client,
      flms: this.firstLineManagers.getValue(),
      territoryIDs: this.territoryIDs.getValue(),
      territoryData: this.territoryData,
      userData: this.userData,
    }
    RRLib.downloadObjectAsFile(saveObject, filename)
  }

  getHolidays(cycle): Promise<any[]> {
    if (this.holidays) {
      return Promise.resolve(this.holidays);
    }

    return this.ds
      .getValueWhere(
        `${this.user.client}/holidays`,
        `${cycle}-01`,
        `${cycle}-99`
      )
      .then(h => {
        if (h) {
          this.holidays = Object.keys(h).map(a => ({ date: a, holiday: h[a] }));
          return this.holidays;
        } else {
          return []
        }
      });
  }

  getPeriodDefinitionData(cycle: string): Promise<any> {
    return this.user.getConfig(`period_definition`)
      .then(pd => {
        return pd[cycle];
      })
  }

  getRoles(): Promise<string[]> {

    return this.user.getLookupsKey('roles')
      .then(r => r ? r.split('||') : [])

  }

  getSecurity(): Promise<any> {
    return this.ds.getValue(`${this.user.client}/security`)
      .then(cs => {
        if (cs) {
          console.log('LOCAL SECURITY', cs)
          return cs
        }
        return this.ds.getValue(`default/security`)
          .then(dS => {
            console.log('Loading DEFAULT SECURITY', dS)
            return dS
          })
      })
  }

  initMaster(tid: string): Promise<void> {

    const uploadMasterList = (master: CustomerData[]): Promise<any> => {

      if (master) {
        const pa = []
        master.forEach(row => {
          if (row.md_id && row.last_name) {
            pa.push(this.ds.fbUpdateValue(`${this.user.client}/doctor_clinic_master/${tid}/${row.md_id}`, row))
          } else {
            master.splice(master.indexOf(row), 1)
          }
        })
        return Promise.all(pa)
      }

      return null
    }

    if (this.masterLists[tid]) {
      return uploadMasterList(this.masterLists[tid])
    } else {
      return this.ds.getValue(`${this.user.client}/doctor_clinic/${tid}`)
        .then(ml => {
          if (ml) {
            this.masterLists[tid] = Object.keys(ml).map(a => {
              const c: CustomerData = ml[a]
              c['id'] = a
              return c
            }).filter(d => d.md_id && d.last_name)

            return uploadMasterList(this.masterLists[tid])
          }

          return Promise.reject(`${tid} has no existing Customers/Doctors`)
        })
    }

  }

  loadCustomerData(event, tid: string) {
    return new Promise((res, rej) => {
      const reader = new FileReader()
      const file = event.target.files[0];
      reader.onload = (event: any) => {
        try {
          const obj = JSON.parse(event.target.result)

          if (obj && (Object.keys(obj).find(a => obj[a].md_id))) {
            this.ds.fbSetValue(`${this.user.client}/doctor_clinic_master/${tid}`, obj)
              .then(rs => {
                res(file.name)
              })
          } else {
            rej('Invalid File... Make sure you upload the file you saved from this screen.')
          }
        } catch (error) {
          rej(`Error encountered: ${error}`)
        }
        console.log('uploaded file', event.target.result)


      }
      reader.readAsText(file)
    })
  }

  loadTerritoryConfig(event): Promise<string> {
    return new Promise((res, rej) => {
      const reader = new FileReader()
      const file = event.target.files[0];
      reader.onload = (event: any) => {
        try {
          const obj = JSON.parse(event.target.result)
          if (obj && (obj['client'] == this.user.client) && obj['flms'] && obj['territoryIDs'] && obj['territoryData'] && obj['userData']) {
            console.log('LOADED', obj)
            this.territoryData = obj.territoryData
            this.userData = obj.userData

            this.firstLineManagers.next(obj.flms)
            this.territoryIDs.next(obj.territoryIDs)

            res(file.name)
          } else {
            rej('Invalid File... Make sure you upload the file you saved from this screen.')
          }
        } catch (error) {
          rej(`Error encountered: ${error}`)
        }
        console.log('uploaded file', event.target.result)


      }
      reader.readAsText(file)

    })
  }

  observeProcessSchedule(): BehaviorSubject<any> {

    const bs = new BehaviorSubject<any>(null)

    this.ds.fbObserveValue(`/default/processing_schedule/${this.user.client}`, (snap) => {
      bs.next(snap.val())
    })

    return bs
  }

  purgeMaster(tid: string): Promise<void> {

    const purgeList = (master: CustomerData[]): Promise<any> => {

      if (master) {
        const pa = []
        master.forEach(row => {
          if (row.md_id && row.last_name) {
            if (row.inclusion_notification) {
              pa.push(this.ds.fbRemoveValue(`${this.user.client}/doctor_clinic_master/${tid}/${row.md_id}/inclusion_notification`))
              delete row.inclusion_notification
            }
            if (row.delete_notification) {
              pa.push(this.ds.fbRemoveValue(`${this.user.client}/doctor_clinic_master/${tid}/${row['id']}`))
              master.splice(master.indexOf(row), 1)
            }
          } else {
            pa.push(this.ds.fbRemoveValue(`${this.user.client}/doctor_clinic_master/${tid}/${row['id']}`))
          }
        })
        return Promise.all(pa)
      }

      return null
    }

    if (this.masterLists[tid]) {
      return purgeList(this.masterLists[tid])
    } else {
      return this.readTerritoryMasterList(tid)
        .then((ml) => {
          return purgeList(ml)
        })
      // return this.ds.getValue(`${this.user.client}/doctor_clinic_master/${tid}`)
      //   .then(ml => {
      //     this.masterLists[tid] = Object.keys(ml).map(a => {
      //       const c: CustomerData = ml[a]
      //       c['id'] = a
      //       return c
      //     })

      //     return purgeList(this.masterLists[tid])
      //   })
    }
  }

  readProcessingSchedule(): Promise<any> {

    if (this.cache['processing_schedule']) {
      return Promise.resolve(this.cache['processing_schedule'])
    }

    return this.ds.getValue(`/default/processing_schedule/${this.user.client}`)

  }

  readTerritoryMasterList(tid: string): Promise<any> {
    return this.ds.getValue(`${this.user.client}/doctor_clinic_master/${tid}`)
      .then(ml => {
        this.masterLists[tid] = Object.keys(ml).map(a => {
          const c: CustomerData = ml[a]
          c['id'] = a
          return c
        })

        return this.masterLists[tid]
      })
  }

  readTerritoryCurrentMDIDs(tid: string): Promise<string[]> {
    return this.ds.shallowRead(`${this.user.client}/doctor_clinic/${tid}`)
      .then((rs) => {
        if (rs) {
          return Object.keys(rs)
        }
        return null
      })
  }

  readTerritoriesWithMasterList(): Promise<string[]> {
    return this.ds.shallowRead(`${this.user.client}/doctor_clinic_master`)
      .then((rs) => {
        if (rs) {
          return Object.keys(rs)
        }
        return null
      })
  }

  readTerritoriesWithCalls(): Promise<any> {
    return this.ds.shallowRead(`${this.user.client}/_call_counters/${RRLib.CYCLE}/daily_calls`).then(wc => {
      if (wc) {
        this.terrsWithCalls = wc
        return this.terrsWithCalls
      }
      return {}
    })

  }

  readTerritoriesWithCustomers(): Promise<any> {
    return this.ds.shallowRead(`${this.user.client}/doctor_clinic`)
      .then((rs) => {
        if (rs) {
          this.terrsWithCustomers = rs
          return this.terrsWithCustomers
        }
        return null
      })
  }

  saveCycle(cycle: any): Promise<void> {
    const d = new Date(`${cycle.currentCycle}-01`)
    d.setDate(d.getDate() + 31)
    const nd = new Date(`${RRLib.dateToLongString(d, 'yyyy-MM')}-01`)
    nd.setDate(nd.getDate() - 1)

    const cycleData = {
      periodCode: cycle.currentCycle,
      description: cycle.currentCycleDisplay,
      start: `${cycle.currentCycle}-01`,
      end: RRLib.dateToLongString(nd, 'yyyy-MM-dd')
    }

    return this.ds.fbSetValue(`${this.user.client}/config/period_definition/${cycle.currentCycle}`, cycleData)
  }

  saveHoliday(h: Holiday): Promise<void> {
    const nDate = RRLib.dateToLongString(new Date(h.date), "yyyy-MM-dd");
    console.log(`${this.user.client}/holidays/${nDate}`);

    // return Promise.resolve();
    return this.ds.fbSetValue(
      `${this.user.client}/holidays/${nDate}`,
      h.holiday
    );
  }

  saveProcessingSchedule(ps: any): Promise<any> {
    this.cache['processing_schedule'] = ps
    return this.ds.fbUpdateValue(`/default/processing_schedule/${this.user.client}`, ps)
  }

  saveSecurity(security: any): Promise<any> {

    return this.ds.fbSetValue(`${this.user.client}/security`, security)
      .then(() => {

        const access = Object.keys(security).reduce((p, c) => {
          const acc = Object.keys(security[c]).filter(a => security[c][a]).join('||')
          p[c] = acc
          return p
        }, {})

        console.log('ACCESS', access)

        const cCounter = {}

        this.userIDList.getValue().forEach(uid => {
          const u = this.userData[uid] as UserData
          const r = u.role

          cCounter[r] = (cCounter[r] || 0) + 1

          if (access[r]) {
            u.access = access[r]
            this.ds.fbSetValue(`${this.user.client}/users/${u.userid}/access`, access[r])
          }

        })

        return cCounter

      })
  }

  saveUser(user: UserData): Promise<any> {
    if (user.userid) {
      return this.ds.fbUpdateValue(`${this.user.client}/users/${user.userid}`, user)
    }
    else {
      return null
    }
  }

  deleteUser(user: UserData): Promise<any> {
    if (user.userid) {
      return this.ds.fbRemoveValue(`${this.user.client}/users/${user.userid}`)
    } else {
      return null
    }
  }
}

export interface Holiday {
  date: string;
  holiday: string;
}

export const Access = {
  sysadmin: 'Admin Access',
  set_plan: 'Can set call plans',
  set_visit: 'Can make call/visits',
  add_md: 'Can add new Customers/Doctors',
  delete_md: 'Can delete Customers/Doctors',
  approve_delete: 'Can Approve Deletions',
  approve_add: 'Can Approve Inclusions',
  approve_ctd: 'Can Approve CTDs'
};

export interface FLM {
  id: string;
  name: string;
  terrs: string[];
  profile_url: string;
}