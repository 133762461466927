import { Component, NgModule, Input } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxChartsModule } from '@swimlane/ngx-charts';
// import { multi } from './data';

@Component({
  selector: 'app-dash-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent {
  @Input('data') data: any[]
  @Input('row') rowField: string
  @Input('series') series: any
  @Input('xAxisLabel') xAxisLabel: string
  @Input('formatDataLabel') formatDataLabelFunction
  @Input('type') chartType?: string
  @Input('title') title: string

  multi: any[];
  view: any[] = [700, 400];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  legendPosition: string = 'below';
  showXAxisLabel: boolean = true;
  yAxisLabel: string
  showYAxisLabel: boolean = true;


  colorScheme = {
    domain: ['#ff0000', '#ffff00', '#00FF00']
  };
  schemeType: string = 'ordinal';

  constructor() {
    this.formatDataLabel = this.formatDataLabel.bind(this)
  }

  formatDataLabel(value) {
    return `${value} `
  }

  ngOnChanges() {
    if (this.data) {
      this.view = [, Math.max(200, this.data.length * 65)]
      this.yAxisLabel = this.rowField.replace(/_/g, ' ').toUpperCase()
      this.multi = []
      this.data.forEach(r => {
        const seriesValue = typeof this.series == 'string' ? this.series.split(',').map(a => { return { name: a.replace(/_/g, ' ').toUpperCase(), value: r[a] || 0 } })
          : this.series.map(a => { return { name: a.title, value: r[a.field] || 0 } })
        // debugger
        if(seriesValue.length > 1) {
          this.multi.push({
            name: r[this.rowField],
            series: seriesValue
          })
        } else {
          this.multi.push({
            name: r[this.rowField],
            value: seriesValue[0].value
          })
        }
      })
    }
  }

  onSelect(data): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    // console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }


}
