import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, Input } from '@angular/core';
import { AdminDataService, FLM } from 'src/app/admin/admin-data.service';
import { BehaviorSubject } from 'rxjs';
import { RRLib } from '../rrlib.service';


@Component({
  selector: 'app-territory-picker',
  templateUrl: './territory-picker.component.html',
  styleUrls: ['./territory-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TerritoryPickerComponent implements OnInit {
  @Input('single') single : boolean
  @Output('onTerritoriesSelected') onTerritoriesSelected = new EventEmitter()

  territoryIDs = new BehaviorSubject<string[]>(null);
  territoryData: any;
  firstLineManagers = new BehaviorSubject<FLM[]>([]);
  // userData = {};

  terrsWithCustomers = {}
  terrsWithCalls ={}
  terrsPageState = {}
  showUpload = ''
  terrsUploading = {}
  selectedTerritory : string

  constructor(public ads : AdminDataService) { 
    
    this.ads.readTerritoriesWithCustomers()
      .then(dcs => {
        if (dcs) {
          this.terrsWithCustomers = dcs
        }
      })

    this.ads.readTerritoriesWithCalls()
      .then(wc => {
        this.terrsWithCalls = wc 
      })

    this.ads.territoryIDs.subscribe(tids => {
      if (tids) {

        this.territoryData = this.ads.territoryData;
        this.territoryIDs.next(tids);
        tids.forEach(tid => {
          const terrState: any = {}

          terrState.masterCopied = 0;
          terrState.allDenied = 0;
          terrState.allApproved = 0;
          terrState.initMasterBusy = false;
          terrState.withCalls = false

          this.terrsPageState[tid] = terrState

        })
      }
    })

    this.ads.firstLineManagers.subscribe(flm => {
      if (flm) {
        const flms = Object.keys(flm).map(a => flm[a]).sort((a, b) => RRLib.sort(a, b, 'name'));
        this.firstLineManagers.next(flms);
        console.log('FLMS',flms)
      }

    })

    // this.as.userIDList.subscribe(uids => {
    //   this.userData = this.as.userData;
    // })?
  }

  ngOnInit() {
    if (this.ads.selectedTerritories.push.length > 0) {
      this.onTerritorySelected()
    }
  }

  private timer
  onTerritorySelected() {
    if (this.ads.selectedTerritories.indexOf('none') !== -1) {
      this.ads.selectedTerritories = []
    }

    if(this.timer) {
      clearTimeout(this.timer)
    }

    this.timer = setTimeout(() => {

      if(this.single) {
        this.ads.selectedTerritories = [this.selectedTerritory]
      }
    
      this.onTerritoriesSelected.emit( this.ads.selectedTerritories )
    }, 1000)


  }

}
