import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dash-table',
  templateUrl: './dash-table.component.html',
  styleUrls: ['./dash-table.component.scss']
})
export class DashTableComponent implements OnInit {
  @Input ('data') data : any[]
  @Input ('options') options : any

  columns : string[]

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.data && this.options && this.options.columns) {
      this.columns = this.options.columns.map( a => {return a.field})
    }
  }

}
