import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/user/user.service';
import { BehaviorSubject } from 'rxjs';
import { RRLib } from '../rrlib.service';
import { IgniteDataService } from '../ignite-data.service';

@Component({
  selector: 'app-cycle-picker',
  templateUrl: './cycle-picker.component.html',
  styleUrls: ['./cycle-picker.component.scss']
})
export class CyclePickerComponent implements OnInit {

  static selectedCycle = new BehaviorSubject<string>(RRLib.CYCLE)

  cycles = new BehaviorSubject<Cycle[]>([])
  selectedCycle = RRLib.CYCLE

  constructor(private user: UserService, private igD: IgniteDataService) { 
    this.igD.selectedMonth.subscribe(m => this.selectedCycle = m)
    this.user.getConfig('period_definition').then((config) => {
      if (config) {
        const nConfig = Object.keys(config)
                              .sort(RRLib.sortDesc)
                              .slice(0,8)
                              .map(a => config[a] as Cycle)
        this.cycles.next(nConfig)
      }
    })
  }

  ngOnInit() {
  }

  onCycleSelected() {
    this.igD.updateSelectedMonth(this.selectedCycle)
    CyclePickerComponent.selectedCycle.next(this.selectedCycle)
  }

}

export interface Cycle {
  periodCode : string
  description : string
  start: string
  end: string
}