import { Component, ViewChild, ElementRef, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-signature',
  // templateUrl: './signature.component.html',
  template: `<canvas *ngIf="!picSign && sign" #canvasEl width="200" height="100"></canvas>
            <ng-signature-pad *ngIf="!picSign && !sign"  [(points)]="points" [showDoneButton]="true" [showClearButton]="true" [responsive]="false"(done)="saveSignature($event)"></ng-signature-pad>
            <img *ngIf="picSign"  width="100" height="100" src="data:image/jpg;base64,{{sign}}">`,

  // `template: `<canvas *ngIf="!picSign" #canvasEl width="200" height="100">
  //             </canvas><img *ngIf="picSign"  width="100" height="100" src="data:image/jpg;base64,{{sign}}">`,
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements AfterViewInit {
  /** Template reference to the canvas element */
  @ViewChild('canvasEl', { static: false }) canvasEl: ElementRef;    /** Canvas 2d context */

  @Output('onSigned') onSigned = new EventEmitter<string>()
  @Input() sign: any;

  private context: CanvasRenderingContext2D;
  private sigCanvas: HTMLCanvasElement;
  picSign = false
  points = [];

  constructor() {

  }


  cord = [];

  // used by ng-signature-pad

  saveSignature(png) {

    const segs = this.points
    const nrows = []

    segs.forEach(seg => {
      const points = seg.points
      console.log(points)
      do {
        const p1 = points.shift()
        const p2 = points[0]

        // const [x,y] = points.splice(0,2)
        // const [dx, dy] = points.slice(0,3)

        if (p2) {
          nrows.push(`${Math.round(p1.x)},${Math.round(p1.y)},${Math.round(p2.x)},${Math.round(p2.y)}`)
        }

        // console.log(`${x},${y},${dx | x},${dy | y}`)

      } while (points.length > 0)
    })

    // const sign = this.signature.map(seg => {
    //   return seg.points.map(pt => `${Math.round(pt.x)},${Math.round(pt.y)}`).join(',')
    // }).join('/')

    const sign = nrows.join('/')

    console.log(sign)
    this.onSigned.emit(sign)
  }



  ngAfterViewInit() {
    // console.log(this.sign)
    if (this.sign && this.sign.indexOf('/9j/') !== -1) {
      this.picSign = true
    }
    else {
      this.context = (this.canvasEl.nativeElement as HTMLCanvasElement).getContext('2d');
      this.sigCanvas = this.canvasEl.nativeElement as HTMLCanvasElement;
      this.draw();
    }
    this.sign_initialize()
  }

  private draw() {
    var sign = this.sign || "";

    if (sign.indexOf(',/') !== -1) {
      var cord = sign.split(",");
      var maxx = Math.ceil(cord[0] / 2);
      var maxy = Math.ceil(cord[1] / 2);
      this.context.moveTo(maxx, maxy);

      for (var i = 0; i < cord.length; i += 2) {
        var p0 = Math.ceil(cord[i] / 2);
        var p1 = Math.ceil(cord[i + 1] / 2);
        var p2 = Math.ceil(cord[i + 2] / 2);
        var p3 = Math.ceil(cord[i + 3] / 2);
        if (cord[i] == "/") {

          this.context.moveTo(p2, p3);
          this.context.lineTo(p2, p3);
          this.context.stroke();
          maxx = Math.max(p2, maxx)
          maxy = Math.max(p3, maxy)
        } else {
          this.context.moveTo(p0, p1);
          this.context.lineTo(p2, p3);
          this.context.stroke();
          maxx = Math.max(p0, p2, maxx)
          maxy = Math.max(p1, p3, maxy)
        }
      }

    } else {
      var cord = sign.split("/");
      for (var i = 0; i < cord.length; i++) {
        var points = cord[i].split(",");
        var p0 = Math.ceil(points[0] / 2);
        var p1 = Math.ceil(points[1] / 2);
        var p2 = Math.ceil(points[2] / 2);
        var p3 = Math.ceil(points[3] / 2);
        this.context.moveTo(p0, p1);
        this.context.lineTo(p2, p3);
        this.context.stroke();
      }
    }

  }

  sign_initialize() {
    // get references to the canvas element as well as the 2D drawing context
    if (this.sign) {
      this.points = this.sign.split('/').reduce((p, c) => {
        const pts = c.split(',')
        p.push({ time: 0, x: parseInt(pts[0]), y: parseInt(pts[1]) })
        p.push({ time: 0, x: parseInt(pts[2]), y: parseInt(pts[3]) })
        return p
      }, [])
    }
    var cord = [];
    var signtext = this.sign
    var sigCanvas = this.sigCanvas
    var context = sigCanvas.getContext('2d');
    context.strokeStyle = 'Black';

    // This will be defined on a TOUCH device such as iPad or Android, etc.
    var is_touch_device = 'ontouchstart' in document.documentElement;

    if (is_touch_device) {
      // create a drawer which tracks touch movements
      var drawer = {
        isDrawing: false,
        touchstart: function (coors) {
          context.beginPath();
          cord.push(coors.x + ',' + coors.y);
          context.moveTo(coors.x, coors.y);
          this.isDrawing = true;
        },
        touchmove: function (coors) {
          if (this.isDrawing) {
            cord.push(coors.x + ',' + coors.y);
            context.lineTo(coors.x, coors.y);
            context.stroke();
          }
        },
        touchend: function (coors) {
          if (this.isDrawing) {
            this.touchmove(coors);
            this.isDrawing = false;
            cord.push("/");
            cord.push("/");
          }
        }
      };

      // create a function to pass touch events and coordinates to drawer
      function draw(event) {

        // get the touch coordinates.  Using the first touch in case of multi-touch
        var coors = {
          x: event.targetTouches[0].pageX,
          y: event.targetTouches[0].pageY
        };

        // Now we need to get the offset of the canvas location
        var obj = sigCanvas;

        if (obj.offsetParent) {
          // Every time we find a new object, we add its offsetLeft and offsetTop to curleft and curtop.
          do {
            coors.x -= obj.offsetLeft;
            coors.y -= obj.offsetTop;
          }
          // The while loop can be "while (obj = obj.offsetParent)" only, which does return null
          // when null is passed back, but that creates a warning in some editors (i.e. VS2010).
          while (obj && obj.offsetParent);
        }

        // pass the coordinates to the appropriate handler
        drawer[event.type](coors);
      }

    } else {


    }
  }



}