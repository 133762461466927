import { Component, OnInit, Input } from '@angular/core';
import { RRFirebaseService } from 'src/app/rrcore/rrfirebase.service';
import { UserService } from 'src/app/user/user.service';
import { BehaviorSubject } from 'rxjs';
import { RRLib } from 'src/app/rrcore/rrlib.service';
import { DashboardComponent } from '../dashboard.component';


@Component({
  selector: 'dash-daily-view',
  templateUrl: './daily-view.component.html',
  styleUrls: ['./daily-view.component.scss']
})
export class DailyViewComponent implements OnInit {
  @Input('selectedMonth') selectedMonth: string
  @Input('data') territories: any[]
  @Input('monthData') monthData


  dailyTotalsObservers = {}
  displayDailyTotals = {}
  dateToday = RRLib.TODAY
  staticTerritoryTotals = {}
  dynamicTerritoryTotals = {}


  territory_ids = new BehaviorSubject<string[]>(null)

  view: any[] = [700, 400];

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };
  cardColor: string = '#232837';

  constructor(private ds: RRFirebaseService, private user: UserService) {

    let mData: any
    let dData: any

    this.territory_ids.subscribe(terrs => {
      if (terrs) {

        console.log('territory_ids updated', terrs)
        terrs.forEach(terr => {
          this.dailyTotalsObservers[terr] = this.ds.observeFSDocument(`/clients/${this.user.client}/months/${RRLib.CYCLE}/territories/${terr}/days/${this.dateToday}`)
        })

        this.territories.forEach(territoryTotals => {

          this.staticTerritoryTotals[territoryTotals.territory_id] = {
            count_off_field: territoryTotals.count_off_field,
            total_plan: territoryTotals.total_plan
          }
        })

        const mData = this.monthData
        if (mData) {
          console.log('mData updated', mData)
          terrs.forEach(dT => {
            this.dailyTotalsObservers[dT].subscribe(dailyTotal => {
              if (dailyTotal) {
                console.log('this territory total was updated', dT, dailyTotal)
                const territoryTotals = this.staticTerritoryTotals[dT]
                if (dailyTotal) {
                  this.displayDailyTotals[dT] = [{
                    name: "Total Calls Today vs Daily Target",
                    actual: dailyTotal.total_calls,
                    target: territoryTotals.total_plan / (mData.count_working_days - (territoryTotals.count_off_field || 0)),
                    class: "col-4"
                  },
                  {
                    name: "Ave Daily Calls vs Daily Target",
                    actual: this.dynamicTerritoryTotals[dT].average_calls_per_day,
                    target: territoryTotals.total_plan / (mData.count_working_days - (territoryTotals.count_off_field || 0)),
                    class: "col-4"
                  },
                  {
                    name: "Total Calls To Date vs Monthly Target",
                    actual: this.dynamicTerritoryTotals[dT].total_calls,
                    target: this.dynamicTerritoryTotals[dT].total_plan,
                    class: "col-4"
                  }
                  ]
                }
              }
            })
          })
        }

      }
    })
  }

  ngOnInit() {

  }

  ngOnChanges() {
    if (this.territories) {
      if (this.territory_ids.getValue() == null) {
        this.territory_ids.next(this.territories.map(a => { return a.territory_id }))
      }
      this.territories.forEach(terr => {
        if (terr.daily_calls) {
          const dCalls: any[] = JSON.parse(terr.daily_calls)
          terr['average_calls_per_day'] = (dCalls.reduce((a, b) => { return a + b.c }, 0) / dCalls.length)
        }

        this.dynamicTerritoryTotals[terr.territory_id] = terr
      })
    }
  }

}
