import { Injectable, OnInit } from '@angular/core';
import { RRFirebaseService } from './rrfirebase.service';
import { UserService } from '../user/user.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { RRLib } from './rrlib.service';

@Injectable({
  providedIn: 'root'
})
export class IgniteDataService implements OnInit {

  oTerritories = {}
  territories = new BehaviorSubject<any[]>(null)
  monthData = new BehaviorSubject<any>(null)
  viewTotals = new BehaviorSubject<any>(null)
  territory_ids = new BehaviorSubject<string[]>([])
  selectedMonth = new BehaviorSubject<string>(RRLib.CYCLE)

  territoryDailyRecord: BehaviorSubject<any>

  isAdmin = new BehaviorSubject<boolean>(false)

  private listeners = {}

  constructor(private ds: RRFirebaseService, private user: UserService) {

    this.selectedMonth.subscribe(selectedMonth => {

      RRLib.log('SELECTED MONTH', selectedMonth)
      if (selectedMonth) {
        Object.keys(this.listeners).forEach(l => (this.listeners[l] as Subscription).unsubscribe)
        this.listeners = {}
        this.monthData.next(null)
        this.viewTotals.next({})
        this.updateData()
      }

    })

    this.user.userData.subscribe(userData => {

      this.isAdmin.next(this.user.isAllowed('admin'))
      if (userData) {
        this.updateData()
      }
    })

  }

  ngOnInit() {

  }

  readTerritoryDailyRecord(territory_id: string, selectedMonth: string): Promise<any[]> {

    return this.ds.getFSCollection(`/clients/${this.user.client}/months/${selectedMonth}/territories/${territory_id}/days`)

  }

  readCallsPerDate(territory_id: string, date: string): Promise<any[]> {
    const month = date.substr(0, 7)
    return this.ds.getFSCollection(`/clients/${this.user.client}/months/${month}/territories/${territory_id}/days/${date}/calls`)
  }

  readNotesPerDate(territory_id: string, date: string): Promise<any[]> {
    const month = date.substr(0, 7)
    const path = `/${this.user.client}/notes/${territory_id}/${month}`

    return this.ds.getValueWhere(path, date, date + '9999')
  }

  readIncidentalsPerDate(territory_id: string, date: string): Promise<any[]> {
    const month = date.substr(0, 7)
    const path = `/${this.user.client}/eforms/incidental_calls_form/${territory_id}/${month}`

    return this.ds.getValueWhere(path, date, date + '9999')
  }

  readActivitiesPerDate(userid: string, date: string): Promise<any[]> {

    const path = `/${this.user.client}/eforms/other_activity_form/${userid}/${date}`

    return this.ds.getValue(path)
  }

  readActivitiesPerMonth(userid: string, selectedMonth: string): Promise<any[]> {
    const path = `/${this.user.client}/activity/${selectedMonth}/${userid}`

    return this.ds.getValue(path)
  }

  // todo: rfb 2021-07-23 15:46:05 - check this if rep changes territory
  readTerritoryConfig(territory_id: string) {
    return this.ds.getValue(`${this.user.client}/territories/${territory_id}/${RRLib.CYCLE}`);

  }

  //Added by Bart 2022-12-09 14:57:30
  readTerritoryConfigBySelectedCycle(territory_id: string, selectedMonth: string) {
    return this.ds.getValue(`${this.user.client}/territories/${territory_id}/${selectedMonth}`);
  }

  //Added by Martin 2022-10-12 14:53:03

  readTerritoryDoctorsPerMonth(territory_id: string, selectedMonth: string): Promise<any[]> {
    return this.ds.getFSCollection(`/clients/${this.user.client}/months/${selectedMonth}/territories/${territory_id}/doctors`)
  }

  updateData(tids?: string[]) {

    RRLib.log('UPDATE DATA', tids)

    const recompute = (terrs: any) => {
      const totalFields = ['total_calls', 'total_plan', 'total_doctors', 'count_doctor_reached']
      const viewTotals = {}
      const today = RRLib.TODAY

      RRLib.log('Territories', terrs)

      terrs.forEach(terr => {
        const selTIDs = this.territory_ids.getValue()
        Object.keys(terr).forEach(field => {
          if (field.indexOf('total') !== -1 || field.indexOf('count') !== -1) {
            viewTotals[field] = (viewTotals[field] || 0) + (terr[field] || 0)
          }
        })

        if (terr && terr.daily_calls) {
          const dc: any[] = JSON.parse(terr.daily_calls)
          dc.forEach(a => {
            if (a.d == today) {
              viewTotals['total_calls_today'] = (viewTotals['total_calls_today'] || 0) + a.c
            }
          })
        }
      })

      this.viewTotals.next(viewTotals)
    }

    const selectedMonth = this.selectedMonth.getValue()
    const userData = this.user.userData.getValue()
    const territories = this.territories.getValue() || []

    if (selectedMonth && userData) {

      this.ds.getFSDocument(`/clients/${this.user.client}/months/${selectedMonth}`)
        .then(data => {
          // RRLib.log('MONTH DATA', data)
          this.monthData.next(data)
        })

      if (userData.access.indexOf('admin') !== -1) {

        const territory_ids = tids || this.territory_ids.getValue()

        if (territory_ids && territory_ids.length > 0) {

          let timer
          territory_ids.forEach(terr => {

            if (!this.listeners[terr]) {

              this.listeners[terr] = this.ds.observeFSDocument(`/clients/${this.user.client}/months/${selectedMonth}/territories/${terr}`)
                .subscribe((tData) => {
                  if (tData) {
                    console.log('A certain territory ', tData);
                    this.oTerritories[terr] = tData
                    // territories.push(tData)
                    if (timer) {
                      clearTimeout(timer)
                    }
                    timer = setTimeout(() => {

                      const filteredTerrs = Object.keys(this.oTerritories).map(tid => this.oTerritories[tid]).filter(t => (territory_ids.indexOf(t.territory_id) !== -1))
                      let unkCtr = 0
                      filteredTerrs.forEach(t => {
                        if (!t.name_rep) {
                          unkCtr += 1
                          t.name_rep = `UA (${t.territory_id})`
                        }
                      })

                      RRLib.log('FILTERED TERRS', Object.keys(filteredTerrs), territory_ids)

                      console.log('A filteredTerrs', filteredTerrs);
                      this.territories.next(filteredTerrs)
                      this.territory_ids.next(territory_ids)

                      recompute(filteredTerrs)
                    }, 1000)
                  }
                })
            } else {
              timer = setTimeout(() => {

                const filteredTerrs = Object.keys(this.oTerritories).map(tid => this.oTerritories[tid]).filter(t => territory_ids.indexOf(t.territory_id) !== -1)

                RRLib.log('FILTERED TERRS', Object.keys(filteredTerrs), territory_ids)

                console.log('B filteredTerrs ', filteredTerrs);
                this.territories.next(filteredTerrs)

                recompute(filteredTerrs)
              }, 1000)
            }
          })
        }
      } else {
        const territory_ids = userData.territory_id.split('||')
        this.territory_ids.next(territory_ids)
        RRLib.log('TERRITORY IDS', territory_ids)

        const selOptions = territory_ids.length == 1 ? {
          field: 'territory_id',
          operator: '==',
          value: territory_ids[0]
        }
          : {
            field: 'name_manager',
            operator: '==',
            value: userData.name
          };

        console.log('territory ids', territory_ids);

        this.ds.observeFSCollectionWhere(`/clients/${this.user.client}/months/${selectedMonth}/territories`,
          selOptions).subscribe(terrs => {
            if (terrs) {

              console.log('territory ids', territory_ids);
              console.log('C certain territory ', terrs);

              this.territories.next(terrs)

              recompute(terrs)
            }
          })
      }
    }

  }

  updateTerritories(terrs: string[]) {
    this.territory_ids.next(terrs)

    RRLib.log('SUBSCRIBE TERRS', terrs)
    this.updateData(terrs)

  }

  updateSelectedMonth(cycle: string) {
    this.selectedMonth.next(cycle)
  }

}
