import { Component, ChangeDetectorRef } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { UserService } from './user/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  isLoggedIn = new BehaviorSubject<boolean>(true)
  title = 'IM2';

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver, private user : UserService,private router : Router) { 
    this.user.isLoggedIn.subscribe(a => {
      this.isLoggedIn.next(a)
      this.router.navigate([this.user.lastPage || ''], {skipLocationChange: true})
    })
  }

  logout() {
    this.user.logout()
        .then(() => {
            this.router.navigate(['/user/login'],  { skipLocationChange: true })
        })
  }

}
