import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'rrDate'
})
export class RRDatePipe implements PipeTransform {

  transform(value: any, format: string): any {
    const dp = new DatePipe('EN-US')
        var d = value
        if (typeof d == 'string') {
            d = d.replace(' +0000', '+00:00').replace(' ', 'T')
            d = d.replace(' +0800', '+08:00').replace(' ', 'T')
        }

        // console.log(d)
        return dp.transform( d, format )
  }

}
