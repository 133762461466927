import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppComponent } from '../app.component';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private user: UserService
  ) {}

  canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let loggedIn = this.user.isLoggedIn.getValue()
    if (!loggedIn) {
      console.log('canActivate')
      console.dir(state)
      this.router.navigate(['/user/login'], { skipLocationChange: true, queryParams: { return: state.url } })
    } else {
      localStorage.setItem('lastpage', state.url)
    }

    console.dir(state)
    if (state.url == '/admin') {
      return this.user.isAllowed('admin')
    }

    return loggedIn;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

}
