import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IgniteDataService } from 'src/app/rrcore/ignite-data.service';
import { RRFirebaseService } from 'src/app/rrcore/rrfirebase.service';
import { RRLib } from 'src/app/rrcore/rrlib.service';
import { UserService } from 'src/app/user/user.service';

@Component({
  selector: 'app-aristo-dashboard',
  templateUrl: './aristo-dashboard.component.html',
  styleUrls: ['./aristo-dashboard.component.scss']
})
export class AristoDashboardComponent implements OnInit {

  selectedMonth = RRLib.CYCLE
  territories = new BehaviorSubject<any[]>(null)
  monthData = new BehaviorSubject<any>(null)
  viewTotals = new BehaviorSubject<any>(null)
  territory_ids = new BehaviorSubject<any[]>(null)

  BUList = new BehaviorSubject<any[]>(null)
  BUKeys = []
  specialtyList = new BehaviorSubject<string[]>(null)

  selectedBU = ''
  selectedSpecialty = ''
  selectedCustomerType = 'Doctors'
  lastSelectedSpecialty = ''
  selectedTab = ''

  constructor(public igD: IgniteDataService, private user: UserService) { }

  ngOnInit() {

    this.igD.territories.subscribe(terr => { this.territories.next(terr) })
    this.igD.selectedMonth.subscribe(sM => { this.selectedMonth = sM })
    this.igD.monthData.subscribe(md => { this.monthData.next(md) })
    this.igD.viewTotals.subscribe(vT => { this.viewTotals.next(vT) })
    this.igD.territory_ids.subscribe(tI => { this.territory_ids.next(tI) })

    this.user.getConfig('BU').then(bu => {
      console.log(bu)
      if (bu) {
        this.BUList.next(bu)
        this.BUKeys = Object.keys(bu)
      }
    })

    this.user.getLookupsKey('specialty').then(sp => { 
      this.specialtyList.next(sp.split('||')) 
    })
  }

  onBUSelected() {
    const tlist = this.BUList.getValue()[this.selectedBU].split('|')
    this.igD.updateTerritories(tlist)
  }

  onCustomerTypeSelected() {
    if(this.selectedCustomerType == 'Drugstore') {
      this.lastSelectedSpecialty = this.selectedSpecialty
      this.selectedSpecialty = 'Drugstore'
    } else {
      this.selectedSpecialty = this.lastSelectedSpecialty
    }
  }

  onSpecialtySelected() {

  }



  onSelectedTabChange(event) {
    console.log(event.tab.textLabel)
    if (this.selectedTab.indexOf(event.tab.textLabel) == -1) {
      this.selectedTab += event.tab.textLabel
    }

  }
  
}
