import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import {MatTableModule} from '@angular/material/table'
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartComponent } from './chart/chart.component';
import { DashTableComponent } from './dash-table/dash-table.component';
import { MatTabsModule } from '@angular/material';
import { TargetViewComponent } from './dashboard/target-view.component';
import { MonthlyViewComponent } from './dashboard/monthly-view/monthly-view.component';
import { DailyViewComponent } from './dashboard/daily-view/daily-view.component';
import { WidgetComponent } from './widget/widget.component';
import { ProductViewComponent } from './product-view/product-view.component';
import { RRCoreModule } from '../rrcore/rrcore.module';
import { MaterialModule } from '../material/material.module';
import { AristoDashboardComponent } from './aristo-dashboard/aristo-dashboard.component';


@NgModule({
  declarations: [DashboardComponent, ChartComponent, DashTableComponent, TargetViewComponent, MonthlyViewComponent, DailyViewComponent, WidgetComponent, ProductViewComponent, AristoDashboardComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    MatTableModule,
    MatTabsModule,
    NgxChartsModule,
    MaterialModule,
    RRCoreModule
  ],
  exports : [DashboardComponent]
})
export class DashboardModule { }