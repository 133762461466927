import { Component, OnInit, Input } from '@angular/core';
import { RRFirebaseService } from 'src/app/rrcore/rrfirebase.service';
import { UserService } from 'src/app/user/user.service';
import { BehaviorSubject } from 'rxjs';
import { RRLib } from 'src/app/rrcore/rrlib.service';
import { IgniteDataService } from 'src/app/rrcore/ignite-data.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  selectedMonth = RRLib.CYCLE
  territories = new BehaviorSubject<any[]>(null)
  monthData = new BehaviorSubject<any>(null)
  viewTotals = new BehaviorSubject<any>(null)
  territory_ids = new BehaviorSubject<any[]>(null)
  selectedTab = ''

  constructor( private igD: IgniteDataService) {}

  ngOnInit() {

    this.igD.territories.subscribe(terr => { this.territories.next(terr) })
    this.igD.selectedMonth.subscribe(sM => { this.selectedMonth = sM })
    this.igD.monthData.subscribe(md => { this.monthData.next( md )})
    this.igD.viewTotals.subscribe(vT => { this.viewTotals.next( vT )})
    this.igD.territory_ids.subscribe(tI => { this.territory_ids.next( tI ) })
    

  }

  onSelectedTabChange(event) {
    console.log(event.tab.textLabel)
    if (this.selectedTab.indexOf(event.tab.textLabel) == -1) {
      this.selectedTab += event.tab.textLabel
    }

  }

  onTerritoriesSelected(terrs) {
    console.log(terrs)
    this.igD.updateTerritories(terrs)
  }

}

export const makeGroupFieldsInverted = (terrs: any[], prefix: string): any => {
  const ndata: any[] = []

  const values = {}
  const retCols = [
    { field: prefix, title: prefix.replace(/[_]/g, ' ').toUpperCase(), class: 'text-left' }
  ]

  terrs.forEach(terr => {
    retCols.push({ field: terr.name_rep, title: terr.name_rep, class: 'text-center' })
    Object.keys(terr).filter(a => { return a.startsWith(prefix) })
      .forEach(a => {
        values[a] = { field: a, title: a.replace(prefix, '').replace(/[_]/g, ' '), class: 'text-center' }
      })
  })

  Object.keys(values).forEach(a => {
    const nrow = {}
    nrow[prefix] = a.replace(prefix, '').replace(/[_]/g, ' ')
    terrs.forEach(terr => {
      nrow[terr.name_rep] = terr[values[a].field] || 0
    })
    ndata.push(nrow)
  })
  // console.log(ndata, retCols)
  return { cols: retCols, data: ndata }
}

export const makeGroupFields = (terrs: any[], prefix: string): any[] => {
  const values = {}
  terrs.forEach(terr => {
    Object.keys(terr).filter(a => { return a.startsWith(prefix) })
      .forEach(a => {
        values[a] = { field: a, title: a.replace(prefix, '').replace(/[_]/g, ' '), class: 'text-center' }
      })
  })

  const retCols = [
    { field: 'name_rep', title: 'Medrep', class: 'text-left' }
  ]

  Object.keys(values).forEach(a => {
    retCols.push(values[a])
  })
  // console.log(retCols)
  return retCols
}
