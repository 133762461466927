import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

import {
    trigger,
    state,
    style,
    animate,
    transition
} from '@angular/animations';

import { ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { RRFirebaseService } from 'src/app/rrcore/rrfirebase.service';



@Component({
    selector: 'rr-widget',
    templateUrl: './widget.component.html',
    styleUrls: ['./widget.component.css'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('animateValue', [
            state('counted', style({
                transform: 'scale(1) rotate(0deg)'
            })),
            state('counting', style({
                transform: 'scale(1.1) rotate(2deg)'
            })),
            transition('counting => counted', animate('300ms')),
            transition('counted => counting', animate('100ms'))
        ])
    ]

})
export class WidgetComponent implements OnInit {
    @Input() widget: any;
    
    name : string
    actual : number = 0
    target : number = 0
    rate : number = 0

    state = 'zero';

    constructor(private ds: RRFirebaseService) {

    }

    

    ngOnInit() {
        this.name = this.widget.name
    }

    ngOnChanges() {
        // this.state = "counting"
        setTimeout(() => {
            this.actual = this.widget.actual
            this.target = this.widget.target
            
            this.rate = (this.actual/this.target) * 100
            // this.state = "counted"
        }, 1000)
    }

}
