import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { RRFirebaseService } from '../rrfirebase.service';
import { UserService } from 'src/app/user/user.service';
import { RRLib } from '../rrlib.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

@Component({
  selector: 'rr-form-entry',
  templateUrl: './rrform-entry.component.html',
  styleUrls: ['./rrform-entry.component.scss']
})
export class RRFormEntryComponent implements OnInit, AfterViewInit {

  @Input('data') data: any;
  @Input('schema') schema: any[];

  private deviceType: string = 'Tablet';
  private oldData = {}
  private lookups = {}
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(private rrd: RRFirebaseService, private user: UserService, private cdr : ChangeDetectorRef) {

  }

  ngOnInit() {

    this.user.config.subscribe(c => {
      if (c) {
        this.cdr.detach()
        this.schema.forEach(field => {
    
            if (field.type) {
    
                if ((field.type == 'select' || field.type =='chip') && field['lookup']) {
                    const lookup = field['lookup']
                    if (this.lookups[lookup]) {
                        field.options = this.lookups[lookup].split('||')
                    } else {
                        this.user.getLookupsKey(lookup).then(lookupString => {
                            field.options = lookupString.split('||')
                            this.lookups[lookup] = lookupString;
                        })
                    }
                }
    
            }
    
            if ( !this.data[field.name] ) {
                this.data[field.name] = ''
            }
    
        })
        this.cdr.reattach()
        this.cdr.detectChanges()
      }
    })
    
  }

  get formData(): any {
      return this.data;
  }

  ngAfterViewInit(): void {

  }

  onTextChange(event, fieldName) {

    console.log(event.value)
      if (event.value && (this.data[fieldName] !== event.value)) {
          this.oldData[fieldName] = this.oldData[fieldName] || event.oldValue
          this.data[fieldName] = event.value
      }

  }

  radioTapped(fieldName, option) {
      console.log(`${fieldName} = ${option}`)
      this.data[fieldName] = `${option}`
  }

  onDatePickerTapped(fieldName, event) {
      this.data[fieldName] = RRLib.dateToLongString( event.object.date, 'yyyy-MM-dd' )
  }

  remove(val: string, key: string): void {
    const s : string[] = this.data[key].split('||')
    const index = s.indexOf(val);

    if (index >= 0) {
        s.splice(index, 1);
        this.data[key] = s.join('||')
    }
  }

  chipsChange(event, key){
    this.data[key] = event
  }

}
