import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserRoutingModule } from './user-routing.module';
import { UserLoginComponent } from './user-login/user-login.component';
import { MaterialModule } from '../material/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { UserDisplayComponent } from './user-display/user-display.component';



@NgModule({
  declarations: [UserLoginComponent, UserDisplayComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    UserRoutingModule
  ],
  exports : [ UserDisplayComponent ]
})
export class UserModule { }
