import { Component, OnInit } from '@angular/core';
import { UserService, UserData } from '../user.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-user-display',
  templateUrl: './user-display.component.html',
  styleUrls: ['./user-display.component.scss']
})
export class UserDisplayComponent implements OnInit {

  userData : BehaviorSubject<UserData> = this.user.userData

  constructor( private user : UserService ) {}

  ngOnInit() {
  }

}
