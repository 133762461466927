import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { UserService } from '../user.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})

export class UserLoginComponent implements OnInit {
  error: string | null;
  busy = false

  form: FormGroup = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });

  constructor(private user: UserService, private route: ActivatedRoute, private router: Router) {
    const lastPage = localStorage.getItem('lastpage') || ''
    this.route.queryParams.subscribe(p => {
      this.user.lastPage = (p.return && (p.return.indexOf('login') !== -1)) ? lastPage : p.return
    })
  }

  ngOnInit() {
  }

  submit() {
    this.busy = true

    if (this.form.valid) {
      this.user.login(this.form.value.username, this.form.value.password)
        .then((v) => {
          this.error = null
        })
        .catch(err => {
          console.log('UserLogin Err', err)
          this.error = err
        })
        .finally(() => {
          this.busy = false
        })
    } else {
      console.log('Not valid', this.form.errors)
      this.busy = false
    }
  }
}
